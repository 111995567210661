/* =Pools
==========================================*/
.pool {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.pool--info {
	width: 75%;
}

.pool--in-out {
	margin: 0;
	width: 20%;
}
