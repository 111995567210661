.nav--comp {
	display: flex;
	justify-content: space-between;
	margin: $strip-padding / 2 0;

	a,
	a:visited {
		width: 30%;
	}

}
