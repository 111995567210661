.nav--social {

}

.nav--social--links {
	justify-content: flex-end;

	li {

	}

	a,
	a:visited {
		margin: 0 0 0 .7em;
	}

}
