/* =Colour Variables
==========================================*/
$alert-yellow:			#ffeec0;
$alert-red:				#ff9695;
$alert-green:			#e6efc2;
$alert-blue:			#d5edf8;

$black:					#252525;
$white:					#fff;

$text-color:			#363636;

$brand-primary:			#005172;
$brand-secondary:		#003f5b;
$brand-tertiary:		#3db7e4;

$brand-coarse:			#3f9c35;
$brand-game:			#00747a;
$brand-sea:				#0088ce;

$link-color:        	$brand-primary;
$link-hover:        	darken($link-color, 9%);


/* =Social colours
==========================================*/
$social-facebook:		#4d70a8;
$social-twitter:		#01baf2;
$social-google:			#e3401d;
$social-linkedin:		#1687b1;


/* =Wrap Width
==========================================*/
$wrap-restrict:			62.5em; // 1000px


/* =Strip Padding
==========================================*/
$strip-padding:			2.5em;


/* =Site Transitions
==========================================*/
$transition-time:		.25s;


/* =Radius
==========================================*/
$radius:				.4em;
