.footer {
	background-color: $brand-secondary;
	padding: $strip-padding 0;
}

.footer--address {
	margin: 0 0 2em;

	p {
		color: $white;
		margin-top: 0;

		span {
			color: $brand-tertiary;
			padding-right: .4em;
		}

		a,
		a:visited {
			color: $white;
			font-weight: $regular;
			text-decoration: none;

			&:hover,
			&:focus {
				color: $white;
				text-decoration: underline;
			}

		}

	}

}

.footer--extra--sage {

	img {
		display: block;
	}

}

.copyright {
	color: $white;
	font-size: .750em;
}



@media only screen and (min-width: 40.625em) {

	@import 'footer_650';

}

@media only screen and (min-width: 50em) {

	@import 'footer_800';

}
