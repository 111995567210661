.nav--comp {
	display: block;
	margin: $strip-padding 0;

	a,
	a:visited {
		background-color: #e1e1e1;
		background-image: url('../images/sprite-comp-icons.png');
		background-repeat: no-repeat;
		border-bottom: 6px solid;
		display: block;
		font-size: 1.375em;
		margin: .6em 0;
		padding: 1.52em 3em 1.52em 4em;
		position: relative;

		&::after {
			background-position: center center;
			background-repeat: no-repeat;
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			right: 0;
			text-indent: -999em;
			top: 0;
			width: 2em;
		}

		&.nav--comp--coarse {
			background-position: 20px 37px;
			border-color: $brand-coarse;
			color: $brand-coarse;

			&::after {
				background-image: url('../images/icon-comp-arrow-coarse.png');
			}

		}

		&.nav--comp--game {
			background-position: 30px -122px;
			border-color: $brand-game;
			color: $brand-game;

			&::after {
				background-image: url('../images/icon-comp-arrow-game.png');
			}

		}

		&.nav--comp--sea {
			background-position: 22px -271px;
			border-color: $brand-sea;
			color: $brand-sea;

			&::after {
				background-image: url('../images/icon-comp-arrow-sea.png');
			}

		}

		&:hover,
		&:focus {
			background-color: darken(#e1e1e1, 10%);
			text-decoration: none;
		}

	}

}


@media only screen and (min-width: 50em) {

	@import 'nav-comp_800';

}
