@import 'flexslider';

.slide {
	font-size: 1em;
	overflow: hidden;
	position: relative;

	&::before {
		background-color: #f26b23;
		background-color: rgba(#f26b23, .7);
		content: '';
		display: block;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 0;
	}

}

.slide--overlay {
	padding: $strip-padding 0;
	position: relative;
	z-index: 1;

	.button-wrap {
		margin-bottom: 0;
	}

}

.slide--title {
	color: $white;
	font-family: $fancy-serif;
	font-size: 1.5em;
	line-height: 1.2;
	margin: 0 0 .4em;
}

.slide--text {
	color: $black;
	font-size: 1.2em;
	line-height: 1.2;
	margin: 0 0 2em;
}

.flex-control-paging {
	bottom: auto;
	position: absolute;
	right: 1em;
	top: 50%;
	transform: translateY(-50%);
	width: auto;
	z-index: 4;

	li {
		display: block;
		margin: .4em 0;

		a,
		a:visited {
			background-color: $white;
			height: 30px;
			width: 5px;

			&:hover,
			&:focus {
				background-color: #b6bf00;
			}

			&.flex-active {
				background-color: #b6bf00;
			}

		}

	}

}


@media only screen and (min-width: 37.5em) {

	@import 'slider_600';

}

@media only screen and (min-width: 50em) {

	@import 'slider_800';

}
