.nav--top {

}

.nav--top--links {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;

	li {
		display: inline-block;
		font-size: 1em;
	}

	a,
	a:visited {
		color: $white;
		font-size: .938em;
		font-weight: $regular;
		padding: .4em .8em;

		&:hover,
		&:focus {

		}

	}

}


@media only screen and (min-width: 37.5em) {

	@import 'nav-top_600';

}
