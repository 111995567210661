.slide {

	&::before {
		height: 200%;
		left: -10%;
		top: -50%;
		transform: rotate(15deg);
		width: 60%;
	}

}
