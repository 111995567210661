.mast {

	.wrap {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

}

.logo {
	margin: .5em 0;
}
