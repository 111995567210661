.slide {

	.wrap {
		align-items: center;
		display: flex;
		min-height: 21.875em;
		padding-bottom: 4em;
		padding-top: 4em;
	}

}

.slide--overlay {
	max-width: 50%;
	padding: 0;
}

.slide--title {
	font-size: 2.25em;
}

.slide--text {
	font-size: 1.5em;
}
