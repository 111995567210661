.order--extras {
	display: flex;
	justify-content: space-between;

	> div {

		&:nth-child(odd) {
			width: 45%;
		}

		&:nth-child(even) {
			width: 45%;
		}

	}

}
