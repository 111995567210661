.nav--landing {
	flex-wrap: nowrap;

	a,
	a:visited {
		order: 1;
		max-width: 33.1%;

		&.current {
			order: 1;
		}

	}

}
