.footer--main {
	display: flex;
	justify-content: space-between;
}

.footer--address {
	width: 50%;
}

.footer--extra {
}
