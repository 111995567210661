.ticket-help {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.ticket-help--notice {
	width: 48%;

	> p {

		&:last-child {
			margin-bottom: 0;
		}

	}

}

.ticket-help--form {
	width: 48%;
}
