.form--search {

	label {
		color: $brand-primary;
		display: block;
		font-family: $fancy-serif;
		font-size: .938em;
		margin: 0 0 1em;
	}

	input {

		&[type="text"],
		&[type="search"] {
			background-color: #ebebeb;
			box-sizing: border-box;
			padding: 1em;
			width: 80%;
			width: calc(100% - 60px);

			&:hover,
			&:focus {
				background-color: darken(#ebebeb, 5%);
			}

		}

		&[type="submit"] {
			background-color: #ebebeb;
			background-image: url('../images/icon-search.png');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: auto;
			border: none;
			display: block;
			height: auto;
			min-height: 50px;
			padding-left: 0;
			padding-right: 0;
			text-indent: -999em;
			width: 80px;

			&:hover,
			&:focus {
				background-color: darken(#ebebeb, 10%);
			}

		}

	}

}

.form--search--inner {
	align-items: stretch;
	display: flex;
	justify-content: flex-start;
}
