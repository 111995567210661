/* =Event Body
==========================================*/
.event--date {
	background-color: #ebebeb;
	color: $brand-primary;
	display: block;
	padding: 1em;

	> p {
		font-size: 1.125em;
		font-weight: $bold;
		margin: 0;
	}

}

.event--tickets {
	display: block;
	margin: $strip-padding 0;
}

.event--ticket-info {
	margin: $strip-padding * 1.4 0;
}


/* =Event Sidebar
==========================================*/
.event--logo {
	align-items: center;
	border-bottom: 1px solid #ebebeb;
	display: block;
	display: flex;
	justify-content: center;
	//height: 260px;
	padding: 2em;
}

.event--logo--inner {
	display: block;
	max-height: 200px;
	overflow: hidden;

	img {
		max-height: 200px;
	}

}

.event--location {
	border-bottom: 1px solid #ebebeb;
	padding: 1.4em;
}

.event--location--heading {
	margin: 0 0 1em;
}

.event--sub-heading {
	color: $brand-primary;
	font-size: .875em;
	font-weight: $bold;
}



/* =Listed Events
==========================================*/
.event--list {
	padding: $strip-padding 0;
}

.event--list--month {
	margin: 0 0 $strip-padding;
}

.event--list--heading {
	border-bottom: 1px solid $text-color;
	font-family: $fancy-sans;
	font-size: 1.375em;
	margin: 0 0 1em;
	padding: 0 0 .4em;

	.b__coarse & {
		border-color: $brand-coarse;
		color: $brand-coarse;
	}

	.b__game & {
		border-color: $brand-game;
		color: $brand-game;
	}

	.b__sea & {
		border-color: $brand-sea;
		color: $brand-sea;
	}

}

.event--listed {
	background-color: $white;
	border: 1px solid #e1e1e1;
	display: block;
	margin: 0 0 2em;

	.event--date {
		text-align: center;

		p {
			font-weight: $regular;
		}

		span {
			padding-right: .3em;
		}

	}

	.button-wrap {
		margin-bottom: 0;
	}

	.event--logo {
		border: 1px solid #e1e1e1;
		border-width: 1px 0 0;
	}

}

.event--details {
	padding: 1.6em;
}

.event--detail {
	color: #959595;
	margin: 1em 0 0;
}

.event--name {
	color: $brand-primary;
	font-family: $fancy-serif;
	font-size: 1.375em;
	margin: 0 0 .2em;

	a,
	a:visited {
		font-weight: $regular;
	}

}



@media only screen and (min-width: 37.5em) {

	@import 'events_600';

}

@media only screen and (min-width: 53.125em) {

	@import 'events_850';

}
