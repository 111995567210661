/* =Base
==========================================*/
html {
	box-sizing: border-box;
	height: 100%;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}



/* =Mid
==========================================*/
.mid {
	padding: $strip-padding 0;

	.home & {
		padding: $strip-padding * 1.5 0;
	}

	&__alt {
		background-color: #f8f8f8;
	}

}


/* =Content
==========================================*/
.content--head {
	display: block;
}

.content--head--user {
	display: block;
	text-align: right;
}

.basket-timer {
	background-image: url('../images/icon-timer.png');
	background-position: right center;
	background-repeat: no-repeat;
	color: #b6bf00;
	font-weight: $bold;
	padding: 0 1.6em;
}



@media only screen and (min-width: 43.750em) {

	@import '700';

}

@media only screen and (min-width: 56.250em) {

	@import '900';

}
