.order-grid {
	display: flex;
	justify-content: space-between;

	> div {

		&:first-child {
			width: 62%;
		}

		&:last-child {
			width: 30%;
		}

	}

}
