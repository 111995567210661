.ticket-help {
	border: 1px solid #e1e1e1;
	margin: 2em 0 0;
	padding: 2em;
}

.ticket-help--notice {
	font-weight: $bold;

	> p {
		font-size: .938em;
		margin-top: 0;
	}

}

@media only screen and (min-width: 50em) {

	@import 'home_800';

}
