/* =Input Styling
==========================================*/
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
select,
textarea,
.field {
	background-color: #f8f8f8;
	border: 1px solid #e0e0e0;
	box-shadow: none;
	display: block;
	font-size: 1em;
	margin: 0;
	padding: .8em 1em;
	vertical-align: middle;
	width: 100%;

	&:hover,
	&:focus,
	&:active {
		background-color: lighten(#f8f8f8, 1.5%);
	}

	&.error,
	&.is-invalid {
		border-color: $alert-red;
		background-color: $white;
		background-position: 99% center;
		background-repeat: no-repeat;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2NDM0NDREQkYwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2NDM0NDREQ0YwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQ3ODRGRkE2RjA0QTExRTI5MjhERkZBMTMzMDYwNzI2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjY0MzQ0NERBRjA0QjExRTI5MjhERkZBMTMzMDYwNzI2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+U8iT5wAAAedJREFUeNqk1U9I02Ecx/HtV3aIFAc1hcRDUoGXRAq0oNLA2CrsEFmHbikZu0iQYIFGYAiegkCpLipBxPpDEBMUzB0EhSG2LhG7hMR2GviPgUTvB57Bw8P3+U23B16HPX8+e/b8nt93wezZSMCnhXETF3AcB5BDCnH8Dq98ExcGHcFn8Ah3cdDni+fxnPDv9oAnTB7CKu6VCFXtChZy56LxUjt+jfuB8toSOth9wd7xWAWhqrUjYR/FRTwWJm+iIPT/w7bQf5ljiZnBg45dtKFX6H+LU8gIY8OEV6vgTkStwXWE8BPTGDHGPqNPz2mCfSOOYkA99TvCt1bhGPL68zMcwmncMuape10jrI+q4BbHi/FLn31S9z2x5tRhTc+1W506ipM+T3oRD4X+8+qtc4SqFvL0z/Fr14S+Szjis8bz9Lvvaq8cwS/wwGfdlqfPSWqTiFlX77o13u9Ym1PBs8JAytpRoy44X9Ft9E/gvbA+rYKn8NcaaMVc8UHgBw4b9/iqUQZ6hOAJFbyDcUflmsEX4a6+wTtHGfhAIUqa1U29Zc2BytouThD8x6xuN5CtMPi2CrXLZkZf/HyZoRFCP7n+QVR4PV7uI/AjGghN7OU/r1ilnqILtfpNC+o6vIFljBKYlhb/F2AAgaBsWR5wRiIAAAAASUVORK5CYII=);
		color: $alert-red;
		outline-color: $alert-red;
	}

	&.success,
	&.is-valid {
		border-color: $alert-green;
		background-color: $white;
		background-position: 99% center;
		background-repeat: no-repeat;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2NDM0NDRERkYwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2NDM0NDRFMEYwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjY0MzQ0NERERjA0QjExRTI5MjhERkZBMTMzMDYwNzI2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjY0MzQ0NERFRjA0QjExRTI5MjhERkZBMTMzMDYwNzI2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+7olkTQAAAfhJREFUeNqklU9oE0EUhzdroWjw0tBeWlDxkEaIp55TsCU9VKIgCrHBelA8CQ1Kr1WPbZrQ3gqtQqvGqxpQc2jBk6BIIaAGD4qNCKURpDSKiPi98gLDsJt//uBjsztvfnk7895sIPAw6/joGMThFJyAXn2+A+9gA57/TaY/eU0OeBgfhGm4DiGnsb7DAszxBz/NAdcKjMJLuNWCqagHbsscN5+L+hmH4QkMOe1L5jzFfNA2PgT34ajTuY7AGuZB0/hmh5m+gS0r8xv1zRvg+gGCHZiOwnF4DP3iB3sQkYxPd2C6CWfhB9Xwlus5+K1j4jXuaq3a+gM1H9OPcAa+7q9lPidZJqHbiIm7Wg22rsEI7FrPSzAMX/T+ADyAKSsu7Fr1KplehLvwCs5DvfBf65p+MypqRbO1FXK9utH4/QKuaqYTsG3E3INJv00Q46px3+XxanJ/Ute2/vqP4FKDza2KcdljIKdnhS0xXYULTaqmLMZFn8FFSFtvt6x70ExFCS5oUXspq2ssa7oEl1swFa+CGFdgtkHgPDyDKy02zyxNU6lXRUZb1EuHYayNNs+Yh5B0WQo+/8fpJnNTZFuzz2OpjoQ2QruSOQlMy35fEGmEGMxY9e1brxobw7TkWA1h6xfckUPb+JhGoE/Hpfvew7qUld/H9J8AAwDpw3WYrxcZ3QAAAABJRU5ErkJggg==);
		color: $alert-green;
		outline-color: $alert-green;
	}

	&[disabled],
	&.is-disabled {
		background-color: transparent;
		cursor: not-allowed;
		border-color: transparent;
		font-weight: $bold;
		font-size: 1.125em;
		padding-left: 0;
		padding-right: 0;
		padding-top: 0;
	}

}

input[type="submit"], .add-team-member{
	@extend %btn;

	&[disabled],
	&.is-disabled {
		background-color: #ebebeb;
		border-color: transparent;
		cursor: not-allowed;
		color: #959595;

		&:hover,
		&:focus {
			background-color: #ebebeb;
		}

	}

}

label {
	color: $brand-primary;
	display: block;
	font-size: .875em;
	font-weight: $bold;
	margin: .2em 0 .4em;
}

textarea {
	max-width: 100%;
	min-height: 120px;
	line-height: 1.5em;
}

select {

}


/* =Form Wraps
==========================================*/
.f-field {
	display: block;
	margin: 0 0 1em;
	position: relative;

	&__radio {

		input {
			margin-right: .5em;
		}

		label {
			color: $text-color;
			display: inline-block;
			font-size: .938em;
			font-weight: $regular;
			margin: 0 1.5em 0 0;
		}

	}

}

.panel {
	border: 1px solid #e0e0e0;
}

.panel-heading {
	border-bottom: 1px solid #e0e0e0;
    padding: 0px;
	p{
		margin:0.8em;
	}
}

.panel-body {
    padding: 0px 15px;
}

.panel-footer {
	background-color: #f8f8f8;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border-top: 1px solid #e0e0e0;
	padding: 0px 15px;
	overflow: auto;
}

@font-face {
	font-family: 'iconfont';
	src:url('../../../assets/stylesheet/icons/iconfont/fonts/iconfont.eot');
	src:url('../../../assets/stylesheet/icons/iconfont/fonts/iconfont.eot?#iefix') format('embedded-opentype'),
		url('../../../assets/stylesheet/icons/iconfont/fonts/iconfont.svg#iconfont')format('svg'),
		//url('../../../assets/stylesheet/icons/iconfont/fonts/iconfont.woff') format('woff'),
		url('../../../assets/stylesheet/icons/iconfont/fonts/iconfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

[class^="ico-"], [class*=" ico-"] {
    font-family: 'iconfont';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ico-cart:before {
    content: "\e0c1";
	padding-right:5px;
}

.table{
	margin-top:5px;
	margin-bottom:15px;
	width:100%;
	p{
		margin:0.5em;
	}
}


/* =Contact Form 7
==========================================*/
.wpcf7 {
	overflow: hidden;
	position: relative;
}

.screen-reader-response {
	height: 1px;
	left: -100%;
	position: absolute;
	width: 1px;
}

.wpcf7-form-control-wrap {
	display: block;
	position: relative;
}

.wpcf7-not-valid-tip {
	background-color: $alert-red;
	border-radius: 0 0 $radius $radius;
	color: darken($alert-red, 40%);
	display: block;
	font-size: .875em;
	margin: 0;
	padding: .7em 1em;

 	&::before {
		font-family: $fancy-sans;
	}

}

.wpcf7-validation-errors {
	background-color: $alert-red;
	color: darken($alert-red, 40%);
	font-size: .875em;
	margin: 2em 0 0;
	padding: .7em 1em;
}



/* =jQuery UI
==========================================*/
.ui-button.ui-selectmenu-button {
	background-color: #f8f8f8;
	border-width: 0;
	border-radius: 0;
	box-shadow: none;
	display: block;
	font-size: 1em;
	margin: 0;
	padding: .8em 1em;
	width: 100%;

	&-closed {

	}

	.ui-selectmenu-icon {
		background-color: $brand-primary;
		background-image: url('../images/icon-arrow-down-white.png');
		background-position: center center;
		background-repeat: no-repeat;
		display: block;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		width: 40px;
	}

	.ui-selectmenu-text {
		font-size: .938em;
	}

	&:hover,
	&:focus {

		.ui-selectmenu-icon {
			background-color: $brand-secondary;
		}

	}

}

.ui-selectmenu-menu {

	> .ui-menu {
		border-width: 0;
	}

	.ui-menu-item {
	}

	.ui-menu-item-wrapper {
		background-color: #f8f8f8;
		border-width: 0;
		border-radius: 0;
		box-shadow: none;
		display: block;
		font-size: 1em;
		margin: 0;
		padding: .8em 1em;
		width: 100%;

		&.ui-state-active {
			background-color: $brand-primary;
			color: $white;
		}

	}

}



@import 'forms-search';

@import 'forms-filter';
