/* =Add Member
==========================================*/
.member--add--inputs {
	display: flex;
	justify-content: space-between;
}

.member--add--input {
	margin: 0;
	width: 47%;
}

.member--add--input--full{
	width: 100%;
}
