.form--filter {

	input[type="submit"] {
		bottom: 1.2em;
		left: 1.2em;
		position: absolute;
		width: 90%;
		width: calc(100% - 2.4em);
	}

}
