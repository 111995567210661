.nav--top {

}

.nav--top--links {
	text-align: right;

	li {
	}

	a,
	a:visited {

		&:hover,
		&:focus {

		}

	}

}
