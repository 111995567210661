/* =Listed Events
==========================================*/
.event--listed {
	align-items: stretch;
	display: flex;
	flex-wrap: wrap;
	justify-content: stretch;

	.event--date {
		display: block;
		width: 100%;
	}

	.event--logo {
		border-width: 0 0 0 1px;
		height: auto;
		width: 50%;
	}

}

.event--details {
	width: 50%;
}
