/* =Content
==========================================*/
.content--head--user {

	a,
	a:visited {
		display: inline-block;
	}

}
