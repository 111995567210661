.footer--extra {
	display: flex;
	justify-content: flex-end;
}

.footer--extra--sage {
	margin-right: $strip-padding;
}


.footer--bottom {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.copyright {
	margin: 0;
}
