/* =Base Ticket
==========================================*/
.ticket {
	align-items: center;
	border-bottom: 2px solid #ebebeb;
	display: flex;
	flex-wrap: wrap;
	padding: 1.4em 0;
	justify-content: flex-start;

	&--head {
		background-color: $brand-primary;
		color: $white;
		font-family: $fancy-serif;
		font-weight: $regular;
		padding: 1em;
	}

	> p {
		margin-bottom: 0;
		margin-top: 0;

		&:last-child {
			text-align: right;
		}
	}

}

.ticket--name {
	font-size: .938em;
	margin: 0;
}

.ticket--quantity {
	font-size: .938em;
	margin: 0;
	text-align: center;
	width: 7em;
}

.ticket--price {
	font-size: .938em;
	margin: 0;
	text-align: center;
	width: 7em;
}

.ticket--buy {
	display: block;
	margin: 1.6em 0 0;
	width: 100%;

	a,
	a:visited {
		display: block;
	}

}

.ticket--second {
	margin-left: auto;
}


@media only screen and (min-width: 43.750em) {

	@import 'tickets_700';

}
