.nav--social {

}

.nav--social--links {
	display: flex;
	justify-content: flex-start;
	list-style: none;
	padding: 0;

	li {

	}

	a,
	a:visited {
		//background-color: $white;
		background-image: url('../images/sprite-social.png');
		background-repeat: no-repeat;
		display: block;
		height: 30px;
		margin: 0 .7em 0 0;
		text-indent: -99em;
		width: 30px;
	}

	.social--facebook {
		background-color: $social-facebook;
		background-position: 10px 4px;

		&:hover,
		&:focus {
			background-color: darken($social-facebook, 10%);
		}

	}

	.social--twitter {
		background-color: $social-twitter;
		background-position: -46px 4px;

		&:hover,
		&:focus {
			background-color: darken($social-twitter, 10%);
		}

	}

}


@media only screen and (min-width: 40.625em) {

	@import 'nav-social_650';

}
