.nav--landing {
	border-top: 6px solid #959595;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.b__coarse & {
		border-color: $brand-coarse;
	}

	.b__game & {
		border-color: $brand-game;
	}

	.b__sea & {
		border-color: $brand-sea;
	}

	a,
	a:visited {
		background-color: #ebebeb;
		color: #959595;
		display: block;
		flex-grow: 1;
		font-size: 1em;
		font-weight: $regular;
		order: 2;
		padding: 1em;
		text-align: center;
		//max-width: 33.1%;
		width: 100%;

		&:hover,
		&:focus {
			background-color: darken(#ebebeb, 10%);
			text-decoration: none;
		}

		&.current {
			color: $white;
			order: 1;

			&.b__coarse {
				background-color: $brand-coarse;
			}

			&.b__game {
				background-color: $brand-game;
			}

			&.b__sea {
				background-color: $brand-sea;
			}

		}

	}

}



@media only screen and (min-width: 37.5em) {

	@import 'nav-landing_600';

}
