.order--notification {
	background-position: center top;
	background-repeat: no-repeat;
	margin: 4em 0;
	padding: 7.5em 0 0;

	&.success {
		background-image: url('../images/icon-order-notice-success.png');
	}

	&.error {
		background-image: url('../images/icon-order-notice-error.png');
	}

}

.order--notification--heading {
	color: $brand-primary;
	font-family: $fancy-serif;
	font-size: 1.375em;
	margin: 0 0 .5em;
	text-align: center;
}

.order--notification--text {
	font-size: 1.125em;
	font-weight: $bold;
	text-align: center;

	> p {
		margin: 0;
	}

}

.order--details {
	background-color: #f8f8f8;
	border: 1px solid #e0e0e0;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 0 0 $strip-padding;
	padding: 1em 2em;
}

.order--detail {
	margin: 1em 0;
	width: 50%;
}

.order--detail--sub {
	color: $brand-primary;
	font-size: .875em;
	font-weight: $bold;
	margin: 0;
}

.order--detail--text {
	font-size: 1.125em;
	font-weight: $bold;
	margin: 0;
}

.order--items {

	.heading--underline {
		margin-bottom: 0;
	}

	.ticket {
		padding-left: 1em;
		padding-right: 1em;
	}

}

.order--items--total {
	padding-right: 1em;
	text-align: right;

	span {
		color: $brand-primary;
		font-size: .875em;
		font-weight: $bold;
		padding-right: .5em;
	}

}

.order--attendees {

	.heading--underline {
		margin-bottom: 0;
	}

}

.order--attendee {
	border-bottom: 2px solid #ebebeb;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0;
	padding: 1.4em 0;

	span {
		display: block;
		margin-right: 1em;
	}

	a,
	a:visited {
		display: block;
	}

}


/* =Member Details
==========================================*/
.member--details {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 0 $strip-padding;
}

.member--detail {
	border-bottom: 1px solid #e0e0e0;
	padding: 1em 0;
	width: 100%;
}

.member--detail--sub {
	@extend .order--detail--sub;
}

.member--detail--text {
	@extend .order--detail--text;
}


/* =Add Member
==========================================*/
.member--add-list {
	margin: 1em 0 $strip-padding;
}

.member--add {
	background-color: $white;
	border: 1px solid #e1e1e1;
	display: block;
	margin: 0 0 1em;
}

.member--add--number {
	background-color: #ebebeb;
	color: $brand-primary;
	display: block;
	font-family: $fancy-serif;
	margin: 0;
	font-size: 1.563em;
	padding: .5em;
	text-align: center;
}

.member--add--options {
	padding: 1em;

	.pool--team {
		border-width: 0 0 1px;
	}
}

.member--add--inputs {
	border-bottom: 1px solid #e0e0e0;
	padding: 0 0 1em;
}

.member--add--input {
	margin: 0 0 1em;
}


/* =Further Information
==========================================*/
.further-info-list {

}

.further-info {
	border-bottom: 1px solid #e0e0e0;
	padding: 1em 0;
	position: relative;

	&:last-child {
		border: 0;
	}

	.f-field {
		margin: 0;
	}

	&--multi {

		.f-field__text {
			margin-top: 1em;
		}

	}

}

.further-info--help {
	background-image: url('../images/icon-help-button.png');
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	height: 20px;
	position: absolute;
	right: 0;
	text-indent: -999em;
	text-transform: uppercase;
	top: .8em;
	width: 20px;
}


/* =Pools
==========================================*/
.pool-list {
	margin: 0 0 2em;
}

.pool {
	border-bottom: 2px solid #e0e0e0;
	padding: 1.4em 0;
	width: 100%;

	&--team {
		padding: 1em 0;
	}

}

.pool--info {

}

.pool--name {
	display: inline-block;
	font-size: 1.125em;
	font-weight: $bold;
	margin: 0;
	padding-right: 1em;

	.pool--team & {
		font-size: .938em;
	}

}

.pool--price {
	display: inline-block;
	font-size: .938em;
	margin: 0;
}

.pool--desc {
	display: block;
	font-size: .875em;
	margin: .5em 0 0;
}

.pool--in-out {
	margin: 1.5em 0 0;
	text-align: right;

	label {
		color: $brand-primary;
		display: inline-block;
		font-size: .875em;
		font-weight: $bold;
		padding-right: .4em;
	}

	input {

	}

}


/* =Timer Alert
==========================================*/
.timer-alert {
	background-color: rgba($black, .7);
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 20;

	&.visible {

	}

}

.timer-alert--inner {
	background-color: $white;
	left: 50%;
	padding: 2em;
	position: absolute;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
}


/* =Order Basket
==========================================*/
.order--basket--wrap {

}

.order--basket {

}


@media only screen and (min-width: 31.25em) {

	@import 'orders_500';

}

@media only screen and (min-width: 37.5em) {

	@import 'orders_600';

}

@media only screen and (min-width: 43.750em) {

	@import 'orders_700';

}

@media only screen and (min-width: 46.875em) {

	@import 'orders_750';

}

@media only screen and (min-width: 56.250em) {

	@import 'orders_900';

}
