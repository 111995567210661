// alerts and notices
%alert {
	border: 1px solid;
	box-shadow: inset 0 0 1px $white;
	margin: 1em 0;
	padding: 1em;
}

.alert--notice {
	@extend %alert;
	background: $alert-yellow;
	border-color: darken($alert-yellow, 30%);

	.alert--heading {
		background-image: url('../images/icon-alert-help.png');
		color: darken($alert-yellow, 50%);
	}

}

.alert--info {
	@extend %alert;
	background: $alert-blue;
	border-color: darken($alert-blue, 5%);

	.alert--heading {
		background-image: url('../images/icon-alert-info.png');
		color: darken($alert-blue, 30%);
	}

}

.alert--error {
	@extend %alert;
	background-color: $alert-red;
	border-color: darken($alert-red, 15%);

	.alert--heading {
		background-image: url('../images/icon-alert-error.png');
		color: #82333c;
	}

}

.alert--success {
	@extend %alert;
	background: $alert-green;
	border-color: darken($alert-green, 5%);

	.alert--heading {
		background-image: url('../images/icon-alert-success.png');
		color: darken($alert-green, 30%);
	}

}

.alert--heading {
	background-position: left center;
	background-repeat: no-repeat;
	font-size: .813em;
	font-weight: $bold;
	margin: 0;
	padding-left: 1.8em;
}

.alert--text {

	p {
		margin: .5em 0 0;
	}

}


/* =Go Live Messages
==========================================*/
.go-live_message {
	display: block;
	padding: 2em 10%;
	text-align: center;
	p {
		font-family: Helvetica, Arial, sans-serif;
		font-weight: 600;
		margin: 0;
		text-transform: uppercase;
	}
}

.go-live_error {
	background-color: $alert-red;
	p {
		color: darken($alert-red, 10%);
	}
}

.go-live_notice {
	background-color: $alert-blue;
	p {
		color: darken($alert-blue, 20%);
	}
}
