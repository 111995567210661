.banner--filter-wrap {
	align-items: stretch;
	display: block;
	display: flex;
	justify-content: flex-start;

	.banner--image {
		width: 100%;
		width: calc(100% - 300px);
	}
}

.banner--filter {
	width: 300px;
}
