.nav--primary {

}

.nav--primary--toggle {
	display: none;
}

.nav--primary--links {
	display: flex;
	justify-content: flex-end;
	margin: 0;

	li {

	}

	a,
	a:visited {

		@media only screen and (max-width: 56.250em) {
			font-size: .9em;
		}

	}

}
