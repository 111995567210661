.nav--primary {

}

.nav--primary--toggle,
.nav--primary--toggle:visited {
	background-color: $brand-primary;
	color: $white;
	display: block;
	font-weight: $regular;
	margin: 1em 0 0;
	padding: .8em;
	text-align: center;

	&:hover {
		background-color: $brand-secondary;
		color: $white;
		text-decoration: none;
	}

	&:focus {
		color: $white;
		text-decoration: none;
	}

}

.nav--primary--links {
	display: none;
	list-style: none;
	margin: 1em 0 0;
	padding: 0;

	li {
		font-size: 1em;
		margin: 0;
	}

	a,
	a:visited {
		color: $white;
		display: block;
		font-weight: $regular;
		text-align: center;
		padding: 1em .7em;

		&:hover,
		&:focus {
			background-color: $brand-secondary;
			text-decoration: none;
		}

		&.current {
			background-color: $brand-secondary;
		}

	}

}


@media only screen and (min-width: 50em) {

	@import 'nav-primary_800';

}

@media only screen and (min-width: 62.5em) {

	@import 'nav-primary_1000';

}
