/* =Content
==========================================*/
.content--head {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin: 0 0 $strip-padding;

	.content--heading {
		margin: 0;
	}

}

.content--head--user {
	margin: 0;
	order: 2;

	a,
	a:visited {
		display: block;
	}

}
