.gallery-item {

	.gallery-columns-2 & {
		width: 45%;
	}

	.gallery-columns-3 & {
		width: 30%;
	}

	.gallery-columns-4 & {
		width: 20%;
	}

}
