.banner {
	display: block;
}

.banner--image {
	display: block;
	padding-bottom: 50%;
}




/* =Banner Filter
==========================================*/
.banner--filter-wrap {

	.banner--image {

	}

}

.banner--filter {
	background-color: $brand-coarse;
	display: block;
	padding: 1.2em;
	position: relative;

	.b__coarse & {

	}

	.b__game & {
		background-color: $brand-game;
	}

	.b__sea & {
		background-color: $brand-sea;
	}

}

.banner--filter--heading {
	color: $white;
	display: block;
	font-family: $fancy-serif;
	font-size: .938em;
	margin: 0;
}



@media only screen and (min-width: 43.750em) {

	@import 'banners_700';

}

@media only screen and (min-width: 50em) {

	@import 'banners_800';

}
