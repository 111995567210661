.form--filter {
	margin: 1.2em 0 0;

	select {

	}

	input[type="submit"] {
		border-color: transparent;
		display: block;
		margin: 2em 0 0;
		width: 100%;

		.b__coarse & {
			background-color: darken($brand-coarse, 15%);
			color: lighten($brand-coarse, 30%);

			&:hover,
			&:focus {
				background-color: lighten($brand-coarse, 30%);
				border-color: darken($brand-coarse, 15%);
				color: darken($brand-coarse, 15%);
			}

		}

		.b__game & {
			background-color: darken($brand-game, 15%);
			color: lighten($brand-game, 60%);

			&:hover,
			&:focus {
				background-color: lighten($brand-game, 60%);
				border-color: darken($brand-game, 15%);
				color: darken($brand-game, 15%);
			}

		}

		.b__sea & {
			background-color: darken($brand-sea, 15%);
			color: lighten($brand-sea, 50%);

			&:hover,
			&:focus {
				background-color: lighten($brand-sea, 50%);
				border-color: darken($brand-sea, 15%);
				color: darken($brand-sea, 15%);
			}


		}

		&[disabled] {

			.b__coarse & {
				background-color: transparent;
				border-color: darken($brand-coarse, 15%);
				color: lighten($brand-coarse, 30%);
			}

			.b__game & {
				background-color: transparent;
				border-color: darken($brand-game, 15%);
				color: lighten($brand-game, 60%);
			}

			.b__sea & {
				background-color: transparent;
				border-color: darken($brand-sea, 15%);
				color: lighten($brand-sea, 50%);
			}

		}

	}

}


@media only screen and (min-width: 50em) {

	@import 'forms-filter_800';

}
