/* =Font Families
==========================================*/
// font stacks
$sans-serif:        "Helvetica Neue", Helvetica, Arial, sans-serif;
$serif:             "Georgia", Cambria, Times New Roman, Times, serif;

$fancy-sans:		'Roboto', $sans-serif;
$fancy-serif:		'Merriweather', $serif;


/* =Font weights in use from Typekit or Google Fonts

	Roboto and Merriweather

Thin			- 100
Regular			- 400
Regular italic	- 400
Bold			- 700

==========================================*/
$light:			100;
$regular:		400;
$italic:		400;
$bold:			700;


/* =Base
==========================================*/
body {
	color: $text-color;
	font-family: $fancy-sans;
	line-height: 1.6;
}


/* =Headers
==========================================*/
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
	font-weight: 500;
	text-rendering: optimizelegibility;

	a {
		text-decoration: none;
	}

}

h1, .h1 {
	font-family: $fancy-serif;
	font-size: 1.375em;
	line-height: 1.333em;
}

h2, .h2 {
	font-family: $fancy-serif;
	font-size: 1.2em;
	line-height: 1.4em;
	margin-bottom: 0.375em;
	margin-top: 2em;

	&:first-child {
		margin-top: 0;
	}

}

h3, .h3 {
	font-size: 1.1em;
}

h4, .h4 {
	color: $brand-primary;
	font-family: $fancy-serif;
	font-size: .938em;
}

h5, .h5 {
	font-size: 0.846em;
	line-height: 2.09em;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.heading--underline {
	border: 1px solid $brand-primary;
	border-width: 0 0 1px;
	color: $brand-primary;
	font-size: .938em;
	margin: 2em 0 0;
	padding: 0 0 .4em;

	.content & {
		margin: 2em 0 1em;
	}

}

.content--heading {
	color: $brand-primary;
	font-family: $fancy-serif;
	font-size: 1.375em;
	margin: 1.4em 0;
}

.sub--heading {
	color: $brand-primary;
	font-size: .875em;
	font-weight: $bold;
	margin: 0;
}


/* =Content
==========================================*/
p {
	hyphens: none;
	font-size: .938em;
	margin: 1.4em 0;
	word-break: keep-all;
}

a,
a:visited {
	color: $brand-primary;
	font-weight: $bold;
	text-decoration: none;

	&:hover,
	&:focus {
		color: $link-hover;
		text-decoration: underline;
	}

}

.home--intro {
	color: $brand-primary;
	font-family: $fancy-serif;
	font-size: 1.250em;
	margin: 0 0 1em;
}


/* =Accessible skip to content
==========================================*/
.content--skip-to,
.content--skip-to:visited {
	height: 1px;
	left: -999px;
	overflow: hidden;
	position: absolute;
	top: auto;
	width: 1px;
	z-index: -999;
	&:focus,
	&:active {
		background-color: hotpink;
		background-color: rgba(hotpink, .8);
		color: $white;
		left: 0;
		height: auto;
		margin: 0;
		padding: 1.5em;
		text-align: center;
		top: 0;
		width: 100%;
		z-index: 999;
	}
}


/* =Captions
==========================================*/
.wp-caption {
	background-color: darken($white, 3%);
	border: 1px solid darken($white, 10%);
	padding: .5em;
	margin: 1em 0 2em;
	max-width: 100%;
	img {
		display: block;
		height: auto !important;
		width: 100%;
	}
	&.alignleft {
		@media only screen and (min-width: 650px) {
			float: left;
			margin-right: 2em;
			max-width: 50%;
		}
	}
	&.alignright {
		@media only screen and (min-width: 650px) {
			float: right;
			margin-left: 2em;
			max-width: 50%;
		}
	}
}

.wp-caption-text {
	color: $text-color;
	margin: 0;
	padding: 1em 0;
}


/* =HRs - We don't really use these though
==========================================*/
hr {
	clear: both;
	display: block;
	margin: 2em 0;
}


/* =Lists
==========================================*/
ul,
ol {


	ul,
	ol {

	}

	> li {

		ul {
			> li {

			}
		}
	}

}

li {
	font-size: .938em;
	margin-bottom: .5em;
	position: relative;

	li {
		font-size: 1em;
	}

}

ul {
	padding-left: 1.5em;

	> li {

	}

}

ol {

	> li {

	}

}

.list {

}

.list--sitemap {
	li {

	}
	a {

	}
	a:visited {

	}
}


/* =Blockquotes
==========================================*/
blockquote {

	p {

	}

	footer {

	}

	cite {

	}

}


/* =Code and Pre
==========================================*/
pre {
	background-color: lighten($black, 20%);
	padding: .4em 1em;
}

code {
	background-color: lighten($black, 20%);
	color: $white;
	font-size: .9em;
	padding: .4em;
}


/* =Text Tricks
==========================================*/
.screen-reader-text {
	display: block;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
}

.hide-text {
	display: none;
}
