.top-bar {
	background-color: $brand-secondary;
	padding: 1em 0;
}

.mast {
	background-color: $brand-primary;
	padding: $strip-padding / 2 0;
}

.logo {
	display: block;
	margin:  0 auto;
	max-width: 12.5em;

	img {
		display: block;
	}

}



@media only screen and (min-width: 50em) {

	@import 'mast_800';

}
