/* =Listed Events
==========================================*/
.event--listed {
	flex-wrap: nowrap;

	.event--date {
		align-items: center;
		display: flex;
		padding: 1.2em;
		width: auto;

		p {
			font-size: 1em;
			text-align: center;
		}

		span {
			display: block;
			font-size: 1.750em;
			line-height: 1;
			padding: 0;
		}

	}

	.event--logo {
		margin-left: auto;
		width: 12.5em;
	}

}

.event--details {
	width: auto;
}

.event--detail {
	margin: 0;
}
