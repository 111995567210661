.nav--end {

}

.nav--end--links {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;

	li {
		font-size: 1em;
		margin: 0;

		&::before {
			color: $white;
			content: '\2022';
			display: inline-block;
			padding: 0 .8em;
		}

		&:first-child {

			&::before {
				display: none;
			}

		}

	}

	a,
	a:visited {
		color: $white;
		display: inline-block;
		font-size: .750em;
		font-weight: $regular;
		padding: .4em 0;
	}

}


@media only screen and (min-width: 50em) {

	@import 'nav-end_800';

}
