/* =Print Base
==========================================*/
* {
	background: transparent !important;
	color: black !important;
	text-shadow: none !important;
	filter:none !important;
	-ms-filter: none !important;
}

a, a:visited {
	color: #444 !important;
	text-decoration: underline;

	// show links on printed pages
	&:after {
		content: " (" attr(href) ")";
	}

	// show title too
	abbr[title]:after {
		content: " (" attr(title) ")";
	}
}

.ir a:after,
a[href^="javascript:"]:after,
a[href^="#"]:after {
	content: "";
}

pre, blockquote {
	border: 1px solid #999;
	page-break-inside: avoid;
}

thead {
	display: table-header-group;
}

tr, img {
	page-break-inside: avoid;
}

img {
	max-width: 100% !important;
}

@page {
	margin: 0.5cm;
}

p, h2, h3 {
	orphans: 3;
	widows: 3;
}

h2,
h3 {
	page-break-after: avoid;
}

// hide content people who print don't need to see
.sidebar,
.page-navigation,
.wp-prev-next,
.respond-form,
nav {
	display: none;
}


/* =Theme specific styling
==========================================*/
