%btn {
	background-color: $brand-primary;
	border: 1px solid $brand-primary;;
	color: $white;
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	font-family: $fancy-serif;
	font-size: 1em;
	font-weight: $regular;
	padding: .8em 3em;
	position: relative;
	text-align: center;

	&:hover,
	&:focus {
		background-color: $link-hover;
		color: $white;
		text-decoration: none;
	}

	&:active {

	}

}

.button-wrap {

	a,
	a:visited {
		@extend %btn;
	}

	&__alt {

		a,
		a:visited {
			background-color: $white;
			border-color: $brand-primary;
			color: $brand-primary;

			&:hover,
			&:focus {
				background-color: $link-hover;
			}

		}

	}

}
