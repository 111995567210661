.order--detail {
	margin: 1em 0;
	width: 33.3%;
}


/* =Member Details
==========================================*/
.member--details {
	display: flex;
	justify-content: space-between;
}

.member--detail {
	width: 42%;

	&__full {
		width: 100%;
	}

}


/* =Add Member
==========================================*/
.member--add-list {

}

.member--add {
	align-items: stretch;
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
}

.member--add--number {
	display: block;
	padding: .5em 1em;
	width: 2.5em;
}

.member--add--options {
	width: 100%;

	.pool--team {

		&:last-of-type {
			border-width: 0;
		}
		
	}

}


/* =Further Information
==========================================*/
.further-info {

	&--multi {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.f-field__radio {
			width: 100%;
		}

		.f-field__text {
			width: 48%;
		}

	}

}
