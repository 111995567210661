.ticket {
	flex-wrap: nowrap;
	padding-bottom: 1em;
	padding-top: 1em;
}

.ticket--price {
	margin: 0 0 0 1em;
	width: auto;
}

.ticket--buy {
	margin: 0 0 0 1em;
	width: auto;

}

.ticket--second {
	margin-left: auto;
}
